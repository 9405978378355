var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[_c('ValidationProvider',{attrs:{"name":_vm.$t('agent.title'),"rules":_vm.isDisplayAll ? 'required' : null},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [(_vm.isDisplayAll)?_c('b-form-group',{attrs:{"label":((_vm.$t('agent.title')) + " " + (_vm.isDisplayAll ? '*' : '')),"label-for":"agent"}},[_c('b-form-select',{attrs:{"state":errors[0] ? false : null},model:{value:(_vm.selectedAgent),callback:function ($$v) {_vm.selectedAgent=$$v},expression:"selectedAgent"}},[_c('b-form-select-option',{attrs:{"value":null}},[_vm._v(_vm._s(_vm.$t('fields.click_select'))+" ")]),_vm._l((_vm.agentList),function(agent,key){return _c('b-form-select-option',{key:key,attrs:{"value":agent}},[_vm._v(_vm._s(("(" + (agent.prefix) + ") " + (agent.name)))+" ")])})],2)],1):_vm._e()]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"full_name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label-cols-sm":"12","label-cols-lg":"3","label":"Full name"}},[_c('b-form-input',{attrs:{"state":errors[0] ? false : null,"aria-invalid":false,"type":"text"},model:{value:(_vm.full_name),callback:function ($$v) {_vm.full_name=$$v},expression:"full_name"}}),_c('b-form-invalid-feedback',{attrs:{"id":"input-live-feedback"}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"phone","rules":{
        min: 10,
        regex: /^[0-9]+$/,
        required: true,
      }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label-cols-sm":"12","label-cols-lg":"3","label":"Truewallet Phone"}},[_c('b-form-input',{attrs:{"state":errors[0] ? false : null,"aria-invalid":false,"max-length":"10","type":"text"},model:{value:(_vm.phone),callback:function ($$v) {_vm.phone=$$v},expression:"phone"}}),_c('b-form-invalid-feedback',{attrs:{"id":"input-live-feedback"}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"password","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label-cols-sm":"12","label-cols-lg":"3","label":"Truewallet Password"}},[_c('b-form-input',{attrs:{"state":errors[0] ? false : null,"aria-invalid":false,"type":"password"},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('b-form-invalid-feedback',{attrs:{"id":"input-live-feedback"}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"pin_code","rules":{
        min: 6,
        regex: /^[0-9]+$/,
        required: true,
      }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label-cols-sm":"12","label-cols-lg":"3","label":"Pin-code"}},[_c('b-form-input',{attrs:{"state":errors[0] ? false : null,"type":"text","max-length":"6"},model:{value:(_vm.pin_code),callback:function ($$v) {_vm.pin_code=$$v},expression:"pin_code"}})],1),_c('b-form-invalid-feedback',{attrs:{"id":"input-live-feedback"}},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)}),_c('div',{staticClass:"text-right"},[_c('b-button',{staticClass:"mr-2",attrs:{"variant":"light"},on:{"click":_vm.onCancel}},[_vm._v(" "+_vm._s(_vm.$t('buttons.cancel'))+" ")]),_c('b-overlay',{staticClass:"d-inline-block",attrs:{"show":_vm.isLoading,"rounded":"","spinner-small":""}},[_c('b-button',{attrs:{"type":"submit","variant":"primary","block":""}},[_vm._v(" "+_vm._s(_vm.$t('buttons.save'))+" ")])],1)],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }