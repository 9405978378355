<template>
  <ValidationObserver v-slot="{ handleSubmit }">
    <form @submit.prevent="handleSubmit(onSubmit)">
      <ValidationProvider
        v-slot="{ errors }"
        :name="$t('agent.title')"
        :rules="isDisplayAll ? 'required' : null"
      >
        <b-form-group
          v-if="isDisplayAll"
          :label="`${$t('agent.title')} ${isDisplayAll ? '*' : ''}`"
          label-for="agent"
        >
          <b-form-select
            v-model="selectedAgent"
            :state="errors[0] ? false : null"
          >
            <b-form-select-option :value="null"
              >{{ $t('fields.click_select') }}
            </b-form-select-option>
            <b-form-select-option
              v-for="(agent, key) in agentList"
              :key="key"
              :value="agent"
              >{{ `(${agent.prefix}) ${agent.name}` }}
            </b-form-select-option>
          </b-form-select>
        </b-form-group>
      </ValidationProvider>
      <ValidationProvider v-slot="{ errors }" name="full_name" rules="required">
        <b-form-group label-cols-sm="12" label-cols-lg="3" label="Full name">
          <b-form-input
            v-model="full_name"
            :state="errors[0] ? false : null"
            :aria-invalid="false"
            type="text"
          ></b-form-input>
          <b-form-invalid-feedback id="input-live-feedback">
            {{ errors[0] }}
          </b-form-invalid-feedback>
        </b-form-group>
      </ValidationProvider>
      <ValidationProvider
        v-slot="{ errors }"
        name="phone"
        :rules="{
          min: 10,
          regex: /^[0-9]+$/,
          required: true,
        }"
      >
        <b-form-group
          label-cols-sm="12"
          label-cols-lg="3"
          label="Truewallet Phone"
        >
          <b-form-input
            v-model="phone"
            :state="errors[0] ? false : null"
            :aria-invalid="false"
            max-length="10"
            type="text"
          ></b-form-input>
          <b-form-invalid-feedback id="input-live-feedback">
            {{ errors[0] }}
          </b-form-invalid-feedback>
        </b-form-group>
      </ValidationProvider>
      <ValidationProvider v-slot="{ errors }" name="password" rules="required">
        <b-form-group
          label-cols-sm="12"
          label-cols-lg="3"
          label="Truewallet Password"
        >
          <b-form-input
            v-model="password"
            :state="errors[0] ? false : null"
            :aria-invalid="false"
            type="password"
          ></b-form-input>
          <b-form-invalid-feedback id="input-live-feedback">
            {{ errors[0] }}
          </b-form-invalid-feedback>
        </b-form-group>
      </ValidationProvider>
      <ValidationProvider
        v-slot="{ errors }"
        name="pin_code"
        :rules="{
          min: 6,
          regex: /^[0-9]+$/,
          required: true,
        }"
      >
        <b-form-group label-cols-sm="12" label-cols-lg="3" label="Pin-code">
          <b-form-input
            v-model="pin_code"
            :state="errors[0] ? false : null"
            type="text"
            max-length="6"
          ></b-form-input>
        </b-form-group>
        <b-form-invalid-feedback id="input-live-feedback">
          {{ errors[0] }}
        </b-form-invalid-feedback>
      </ValidationProvider>
      <div class="text-right">
        <b-button variant="light" class="mr-2" @click="onCancel">
          {{ $t('buttons.cancel') }}
        </b-button>
        <b-overlay
          :show="isLoading"
          rounded
          spinner-small
          class="d-inline-block"
        >
          <b-button type="submit" variant="primary" block>
            {{ $t('buttons.save') }}
          </b-button>
        </b-overlay>
      </div>
    </form>
  </ValidationObserver>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'BankTruewalletAccountForm',
  props: {
    value: {
      type: Object,
      default: () => {},
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      phone: '',
      password: '',
      pin_code: '',
      full_name: '',
      selectedAgent: null,
      agent_id: '',
    }
  },
  watch: {
    value(val) {
      if (val) {
        this.setData()
      }
    },
  },
  // eslint-disable-next-line vue/order-in-components
  computed: {
    ...mapGetters(['isMaster', 'agents', 'isOwner']),
    agentList() {
      return this.agents.items || []
    },
    isDisplayAll() {
      return !!(this.isOwner || this.isMaster)
    },
  },
  created() {
    this.setData()
  },
  methods: {
    async setData() {
      if (this.value && this.value.id) {
        this.phone = this.value.username
        this.pin_code = this.value.pin
        this.password = this.value.password
        this.full_name = this.value.full_name
        this.agent_id = this.value.agent_id
      } else {
        this.phone = ''
        this.pin_code = ''
        this.password = ''
        this.full_name = ''
        this.agent_id = null
      }
    },
    onSubmit() {
      this.$emit('submit', {
        pin: this.pin_code,
        username: this.phone,
        password: this.password,
        full_name: this.full_name,
        agent_id: this.selectedAgent.id,
      })
    },
    onCancel() {
      this.$emit('close')
    },
    verifyAccount() {},
  },
}
</script>
